<template>
  <a-card class="mb10">
    <a-button size='small' @click="()=>{$router.go(-1)}">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title" v-if='$route.query.type == "look"'>小程序推送模板详情</span>
    <span class="ml15 card_title" v-else>{{$route.query.type == 'add' ? '添加':'编辑'}}小程序推送模板</span>
  </a-card>
  <a-card :bordered="false">
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="title" label="标题" name="title">
          <a-input v-model:value="form.title" placeholder='请输入标题' />
        </a-form-item>
        <a-form-item ref="keywords" label="模板key" name="keywords">
          <a-input v-model:value="form.keywords" placeholder='请输入模板key'  />
        </a-form-item>
        <a-form-item ref="cate_id" label="类目id" name="cate_id">
          <a-select v-model:value="form.cate_id" placeholder="请选择类目id">
            <a-select-option :value="item.id" v-for='item in cateList' :key='item'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="tid" label="微信公共模板tid" name="tid">
          <a-input v-model:value="form.tid" placeholder='请输入微信公共模板tid'  />
        </a-form-item>
        <a-form-item ref="keywords_list" label="关键词" name="keywords_list">
          <a-button type="primary" @click="showKeyAdd=true">添加</a-button>
          <div class="m-t-10" v-if='form.keywords_list.length'>
            <template v-for="item in form.keywords_list" :key='item'>
              <a-tag closable @close="closeTag(item)">{{item.name}}</a-tag>
            </template>
          </div>
        </a-form-item>
        <a-form-item ref="sceneDesc" label="使用场景说明" name="sceneDesc" >
          <a-textarea v-model:value="form.sceneDesc" showCount :maxlength="200" placeholder="请输入使用场景说明" :rows="4" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button class="m-r-20" @click="()=>{$router.go(-1)}">
            取消
          </a-button>
          <a-button type="primary" @click="onSubmit" :loading='loading'>
            保存
          </a-button>
        </a-form-item>
      </a-form>
  </a-card>
  <a-modal :keyboard="false" title="添加关键词" centered v-model:visible="showKeyAdd" :width="800" :footer="null" destroyOnClose>
    <keyForm @onsubmit='keyAdd'></keyForm>
  </a-modal>
</template>
<script>
  import { LeftOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from 'vue';
  import { message } from 'ant-design-vue';
  import { $iscode } from "@/utils/app";
  import { getMiniAppCateList, miniAppAdd, miniAppEdit, getMiniAppMore} from "@/api/message";
  import keyForm from './keyForm.vue'
  // 初始化默认筛选项数值
  let defForm = {
    title: '',
    keywords: '',
    cate_id: undefined,
    tid:'',
    sceneDesc:'',
    keywords_list:[],
    type:2
  }
  export default {
    components: { LeftOutlined, keyForm },
    setup(props,{emit}) {
      const form = ref({ ...defForm });
      let keywords = async () => {
        if (!form.value.keywords_list.length) {
          return Promise.reject("请选择关键词");
        } else {
          return Promise.resolve();
        }
      };
      const state = reactive({
        showKeyAdd:false,
        loading:false,
        cateList:[],
        labelCol: { span: 3 },
        wrapperCol: { span: 10 },
        rules: {
          title:[
          { required: true, message: '请输入标题'}
          ],
          keywords: [
            { required: true, message: '请输入模板key'}
          ],
          cate_id: [
            { required: true, message: '请选择类目id'}
          ],
          tid: [
            { required: true, message: '请输入微信公共模板tid'}
          ],
          keywords_list: [
            { required: true, validator: keywords}
          ],
          sceneDesc: [
            { required: true, message: '请输入使用场景说明'}
          ],
        }
      });
      const $route = inject("$route");
      const $router = inject("$router");
      //删除关键词
      const closeTag = removedTag => {
        const tags = form.value.keywords_list.filter(v => v != removedTag);
        form.value.keywords_list = tags;
      };
      //添加关键词
      const keyAdd = (e) =>{
        form.value.keywords_list.push(e)
        state.showKeyAdd = false
      }
      //获取类目
      const getCateList = async () => {
        var res = await getMiniAppCateList().then((res) => res.data);
        if ($iscode(res)) {
          state.cateList=res.data.data
        } else {
          message.error(res.msg);
        }
      };
      const _lodash = inject("_lodash");
      //获取详情
      const getMore = async () => {
        const hide = message.loading("正在加载中...", 0);
        var res = await getMiniAppMore($route.query.id).then((res) => res.data);
        if ($iscode(res)) {
          form.value = res.data;
          form.value.keywords_list = res.data.key;
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      const ruleForm = ref()
      const onSubmit = () => {
        ruleForm.value.validate().then(async() => {
          const hide = message.loading('正在加载中...', 0);
          state.loading = true
          let res
          if ($route.query.type == 'edit') {
            res = await miniAppEdit(form.value).then(res => res.data);
          } else {
            res = await miniAppAdd(form.value).then(res => res.data);
          }
          if ($iscode(res)) {
            message.success(res.msg);
            setTimeout(() => {
              $router.go(-1);
            }, 1000);
          } else {
            message.error(res.msg);
          }
          setTimeout(hide);
          state.loading = false
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
        getCateList();
        if($route.query.type == 'edit'){
          getMore()
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        closeTag,
        onSubmit,
        keyAdd
      }
    }
  }
</script>
<style>
</style>